<script setup lang="ts">
	import { useRouteStore } from "@/store/routeStore";
  import * as bootstrap from "bootstrap";
	import { useLayoutStore } from "~/store/layoutStore";
	import { useUserStore } from "~/store/userStore";
	// import Vue from 'vue';

	// Vue.onMounted

	const routeStore = useRouteStore();
	const layoutStore = useLayoutStore();
  const userStore = useUserStore();

  const isSideOpen = ref(false);

  const route = useRoute();
  const router = useRouter();

  const originData = computed(() => {
    return router.options.routes[0].children?.find(v => v.name === 'toc')?.children?.filter(v => v.meta[userStore.getUserType] === true);
  })
  
	const sideBarRouteList = computed(() => {
    let tocRouterList = JSON.parse(JSON.stringify(originData.value));

    // tocRouterList = tocRouterList?.filter(v => v.meta.companyAdmin === undefined || v.meta.companyAdmin === userStore.getUserAdmin);
  
    tocRouterList?.forEach(route => {
      route = childrenAuthGroupSearch(route);
    })
    return tocRouterList;
	});

  // function childrenAuthGroupSearch(route: any){
  //   if(route.children){
  //     route.children.forEach((child, i) => {

  //    if(!((!child.meta || !child.meta.userAuthGroup) || child.meta.userAuthGroup.includes(userStore.getUserAuthType))){
  //         route.children.splice(i, 1);
  //       } 

        

  //       if(child.children) childrenAuthGroupSearch(child);
  //     })

  //   }

  //   return route;
  // }
  
  function childrenAuthGroupSearch(route: any){

    if(route.children){
      for(let i = 0; i < route.children.length; i++){
        let child = route.children[i];

        // if(child.meta){
        //   console.log(child.meta);
        //   console.log(!child.meta.companyAdmin === userStore.getUserAdmin);
        //   console.log(!(!(child.meta && child.meta.companyAdmin) || !child.meta.companyAdmin === userStore.getUserAdmin));
        // }

        if(child.children){
          childrenAuthGroupSearch(child);
        }

        // if(child.meta && child.meta.userAuthGroup){
        //   console.log(!(!(child.meta && child.meta.userAuthGroup) || !child.meta.userAuthGroup.includes(userStore.getUserAuthType)));
        // }
      
        if(!(!(child.meta && child.meta.userAuthGroup) || child.meta.userAuthGroup.includes(userStore.getUserAuthType))){
          route.children.splice(i, 1);
          i--;
        }

        if(!(!(child.meta && child.meta.companyAdmin) || (child.meta.companyAdmin === userStore.getUserAdmin))){
          route.children.splice(i, 1);
          i--;
        }

      }
   }
   return route;
  }

	const side_open = ref(null as HTMLElement | null);
	const side_closed = ref(null as HTMLElement | null);

	onMounted(() => {
		side_open.value = document.getElementById("side_open") as HTMLElement;
		side_closed.value = document.getElementById("side_closed") as HTMLElement;

    document.body.addEventListener('click', () => {
      checkAllRouteEvent();
    }, true)

	});

  function checkAllRouteEvent(){
    let sideMenuPopup = document.querySelectorAll("ul[id*=routeDropDown]");
    
    for(let i = 0; i < sideMenuPopup.length; i++){
      if(sideMenuPopup[i].classList.contains('show')){
        sideMenuPopup[i].classList.remove('show');
      }
    }
  }
  
  function collapseWorking(id: string){

    let collapseList = document.querySelectorAll('div[id*=routerPageCollapse]');
    let firstShowCollapse = '';



    for(let i = 0; i < collapseList.length; i++){
      if(collapseList[i].classList.contains('show')){
        firstShowCollapse = collapseList[i].id;
        const collapse = new bootstrap.Collapse(`#${collapseList[i].id}`);
        collapse.hide();
      }
    }


    try {
      if(firstShowCollapse !== id){
        const collapse = new bootstrap.Collapse(`#${id}`);
        collapse.show();
      }
    } catch (e) {
      console.log('Bootstrap error: ', e);
    }
  }

  function closeDropdownMenus(){

    const sideMenus = document.getElementsByClassName('sidebar-menu');

    for(let i = 0; i < sideMenus.length; i++){
      const dropmenu = new bootstrap.Dropdown(`#${sideMenus[i].id}`);
      dropmenu.hide();
    }
  }

  

  function dropRightWorking(id: string){
    const dropdowns = new bootstrap.Dropdown(`#${id}`)

    const popup = document.getElementById(id);
 
    try{
      if(popup?.classList.contains('show')){
        closeDropdownMenus();
      } else {
        closeDropdownMenus();
        dropdowns.show();
      }    
    } catch(e){
      console.log('Bootstrap error: ', e);
    }
  }

	function sideOpenClose(target: string) {

    // console.log(target, isSideOpen.value);

		let side_menu = document.getElementsByClassName("side_menu")[0];
		let wrapper = document.getElementsByClassName("wrapper")[0];
		let teb_depthmenu = document.getElementsByClassName("teb_depthmenu")[0];

    if(!side_menu || !wrapper || !teb_depthmenu) return;

		if (target === "open") {
      isSideOpen.value = true;

			if (side_menu.classList.contains("on")) {
				side_menu.classList.remove("on");
       
			} else {
				side_menu.classList.add("on");
			}

			(side_open.value?.style as CSSStyleDeclaration).display = "none";
			(side_closed.value?.style as CSSStyleDeclaration).display = "block";
			wrapper.classList.add("sideon");
			teb_depthmenu.classList.add("sideon");
			side_menu.classList.add("sideMenuEnabled");
			layoutStore.setSidebar(true);
		} else if (target === "close") {
      isSideOpen.value = false;

			side_menu.classList.remove("on");
			(side_open.value?.style as CSSStyleDeclaration).display = "block";
			(side_closed.value?.style as CSSStyleDeclaration).display = "none";
			wrapper.classList.remove("sideon");
			teb_depthmenu.classList.remove("sideon");
			side_menu.classList.remove("sideMenuEnabled");
			layoutStore.setSidebar(false);
		}
	}

	async function selectTocMenu(route: any, navigate: Function) {
		routeStore.selectTocRouteInfo(route);
		await navigate();

		let wrapper = document.getElementsByClassName("wrapper")[0];
		let side_menu = document.getElementsByClassName("side_menu")[0];

  if (side_menu.classList.contains("on")) {
			wrapper.classList.add("sideon");
		}
	}

  function innerRouterPush(route: any,parentPath:string, childPath: string){
    closeDropdownMenus();
    routeStore.selectTocRouteInfo(route)
    router.push(`/toc/${parentPath}/${childPath}`);
  }
</script>

<template>
	<div class="side_menu" id="__nuxt" :style="{overflow: 'visible', zIndex: '99'}" >
		<div class="top">
			<div id="side_open" @click="sideOpenClose('open')"><img src="@/assets/images/toc/menu-open.svg" /></div>
			<div id="side_closed" :style="{ display: 'none' }" @click="sideOpenClose('close')"><img src="@/assets/images/toc/menu-closed.svg" /></div>
		</div>
    <div id="accordion" v-if="isSideOpen === true">
      <div v-for="(routeBase, i) in sideBarRouteList" :key="`mainMenu-${routeBase.meta.name}`" class="btn btn-link collapseBtn" :data-target="`#routerPageCollapse${i}`" aria-expanded="true" :aria-controls="`routerPageCollapse${i}`">
        <ul>
          <li>
            <a @click="collapseWorking(`routerPageCollapse${i}`)">
              <div class="icon"><img :src="routeBase.icon" /></div>
              <p>{{ routeBase.meta ? routeBase.meta.name : '-'}}</p>
            </a>
          </li>
        </ul>
        <div>
          <div :id="`routerPageCollapse${i}`" class="collapse collapseItem" aria-labelledby="headingOne" data-parent="#accordion">
            <NuxtLink  v-for="(route, j) in routeBase.children" :key="`subMenu-${route.meta.name}`" :to="`/toc/${routeBase.path}/${route.path}`" :custom="true" activeClass="on" replace v-slot="{ isActive, navigate }" >
              <ul @click="selectTocMenu(route, navigate)" :style="{justifyContent: 'left'}">
                <li :style="isActive ? 'on' : ''">
                  <a>
                    <p>{{ route.meta ? route.meta.name : '-' }}</p>
                  </a>
                </li>
              </ul>
            </NuxtLink>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-for="(routeBase, i) in sideBarRouteList" class="btn-group dropend" >

        <ul :style="{justifyContent: 'left'}"  class="sidebar-btn btn btn-link" id="sidebar-menu" data-bs-toggle="dropdown" aria-expanded="false">
          <li>
            <a @click="dropRightWorking(`routeDropDown${i}`)" >
              <div class="icon"><img :src="routeBase.icon" /></div>
              <p>{{ routeBase.meta ? routeBase.meta.name : '-'}}</p>
            </a>
          </li>
        </ul>
        
        <ul :id="`routeDropDown${i}`" class="dropdown-menu sidebar-menu" aria-labelledby="sidebar-menu">
          <li v-for="(route, j) in routeBase.children" :style="{padding:'10px'}" class="inner-sidebar-menu" @click="innerRouterPush(route, routeBase.path, route.path)">
            <p>{{ route.meta ? route.meta.name : '-' }}</p>
          </li>
        </ul>
      </div>

 
    </div>
	</div>
</template>

<style lang="scss" scoped>

  .sidebar-btn{
    padding: 0
  }
  .btn-group{
    .sidebar-menu{
      background-color: #27334A;
      transform: translate(60px, 0px) !important;
      z-index: 99;
      .inner-sidebar-menu{
          color: #fff;
          font-size: 14px;
          cursor: pointer;
      }
    }
  }
  #accordion{
    overflow-y: auto;
    height: calc(100% - 54px - 70px);

    .collapseBtn{
      width: 100%;
      padding: 0;
      text-decoration: none;

      .collapseItem{
        background-color: #27334A;
        padding-left: 50px;
        font-size: 14px;
      }
    }
  }

	.side_menu.sideMenuEnabled p {
		display: block !important;
	}
</style>
